import logger from '@/logger'
import app_config from '@/config'

var tmt = {
    create_contact(targetUrl, params) {
      $.ajax({
        type: "POST",
        dataType: "json",
        headers: {'Access-Control-Allow-Origin':'*'},
        crossDomain: true,
        cache: false,
        contentType: 'application/json; charset=utf-8',
        url: targetUrl,
        data: JSON.stringify(params),
        success: function(data) {
            logger.alert("create_contact Success:");
            logger.alert(JSON.stringify(data));
            if(typeof data !== 'undefined' && data != null && data.id != -1){
                $(".simple-email-form-success-message").show();
            } else {
                $(".simple-email-form-failure-message").show()
            }
        },
        error: function(data) {
          logger.alert("create_contact Error:");
          logger.alert(JSON.stringify(data));
          $(".simple-email-form-failure-message").show()
          return -1;
        },
        done: function(data) {
          logger.alert("create_contact Done:");
          logger.alert(JSON.stringify(data));
        },
        fail: function() {
          logger.alert("create_contact Fail:");
          logger.alert(JSON.stringify(data));
          $(".simple-email-form-failure-message").show()
          return -1;
        }
      });
    },

    //currently no in use
    update_contact(targetUrl, params) {
      $(".simple-email-form-success-message").hide();
      $(".simple-email-form-failure-message").hide();

      $.ajax({
        type: "PUT",
        crossDomain: true,
        headers: {'Access-Control-Allow-Origin':'*, *'},
        dataType: 'json',
        cache: false,
        contentType: 'application/json; charset=utf-8',
        url: targetUrl,
        data: JSON.stringify(params),
        success: function(data) {
          $(".simple-email-form-success-message").show()
        },
        error: function(data) {
          // Server error, e.g. 404, 500, error
          $(".simple-email-form-failure-message").show()
        },
        done: function(data) {
        },
        fail: function() {
          return -1;
        }
      });
    },

    get_form_fields() {
            const fullname  = $('.simple-email-form').find('input[name="fullname"]').val();
            const email     = $('.simple-email-form').find('input[name="email"]').val();
            const address   = "";
            const business  = $('.simple-email-form').find('input[name="business"]').val();
            const phone     = $('.simple-email-form').find('input[name="phone"]').val();
            const acceptTerms = $('.simple-email-form').find('input[name="acceptTerms"]').val();

            //Here using dummy turnstile token
            var contact_params = {"fullname":    fullname,
                                    "email":     email,
                                    "business":  business,
                                    "phone":     phone,
                                    "address":   address,
                                    "immediateProject": acceptTerms === "on" ? true : false,
                                    "formId": "tmtweb1",
                                    "turnstile-token": "1x00000000000000000000AA"
            };

            return contact_params;
    },

    collect_form_and_submit(token) {
      console.log("creating contact for successfully verified form");
      let params = tmt.get_form_fields();
      params['turnstile-token'] = window.turnstileToken;
      console.log('sending: ' + JSON.stringify(params));
      tmt.create_contact(app_config.endpoints.host, params);
    },

    init_page() {
        const sitekey = '0x4AAAAAAAhcNb4MpqhrCNFc';

        const buildInfo = "Collector Version: " + app_config.release.deploy + " " + app_config.release.version;
        $(".build-version-field").text(buildInfo);
        $(".simple-email-form-success-message").hide();
        $(".simple-email-form-failure-message").hide();

        turnstile.ready(function () {
          turnstile.render('#turnstile', {
              sitekey: sitekey,
              callback: function(token) {
                console.log('Token: ' + token);
                window.turnstileToken = token;
              },
          });
        });
    }
}
export default tmt;