var app_config = {
    turnstile_sitekey: "0x4AAAAAAAhcNb4MpqhrCNFc",
    endpoints: {
        host: 'https://yfwf6rwiyohv3cjj3vptijg6ue0nvcfw.lambda-url.us-east-2.on.aws',
        //host: 'https://68d1axt7df.execute-api.us-east-2.amazonaws.com/default/AirtableFormHandler',
        port: '80'
    },
    release: {
        deploy: 'production',
        version: '0.0.1'
    },
}
export default app_config;