<!--https://stackoverflow.com/questions/62242149/how-to-render-multiple-vue-single-file-components-on-a-page-->
<template>
<!--div id="tmtform" class="footer_section"-->
<!--div class="container w-container"-->

<!--h3 class="footer_heading2">Let&#x27;s craft some digital purpose.</h3-->

  <Form @submit="onSubmit"
      id="wf-form-intake-form"
      name="wf-form-intake-form"
      data-name="intake-form"
      class="simple-email-form"
      :validation-schema="schema" v-slot="{ errors }">

      <label for="fullname" class="home-contact-label">Full Name</label>
      <!--TODO: Turn off values fields in Production-->
      <Field type="text" value="A Name" class="input_home_contact w-input" maxlength="256" name="fullname" data-name="fullname" placeholder="Your fullname" id="intake-form-fullnamefield" required=""/>
      <div class="invalid-feedback error-text">{{errors.fullname}}</div>

      <label for="email" class="home-contact-label">Email Address</label>
      <Field type="email"  value="bob@test.com" class="input_home_contact w-input" maxlength="256" name="email" data-name="email" placeholder="your@email.com" id="intake-form-emailfield" required=""/>
      <div class="invalid-feedback error-text">{{errors.email}}</div>

      <label for="business" class="home-contact-label">Business</label>
      <Field type="text"  value="A Business" class="input_home_contact w-input" maxlength="256" name="business" data-name="business" placeholder="Name of business" id="intake-form-businessfield" required=""/>
      <div class="invalid-feedback error-text">{{errors.business}}</div>

      <label for="phone" class="home-contact-label">Phone</label>
      <Field type="tel"  value="8165551212" class="input_home_contact w-input" maxlength="256" name="phone" data-name="phone" placeholder="e.g. (111)-555-1212" id="intake-form-phonefield" required=""/>
      <div class="invalid-feedback error-text">{{errors.phone}}</div>

      <table style="width: 100%; margin-left: 0px auto; margin-right: 0px auto; text-align: center;">
      <tr><td>
      <Field type="checkbox" id="acceptTerms" name="acceptTerms" data-name="immediate_project" checked="" class="w-checkbox-input checkbox" style="vertical-align:middle; display: inline-block;"/>
      <label for="acceptTerms" class="form-check-label" style="margin-left: 5px; vertical-align:middle; display: inline-block;">I need to start a project immediately</label>
      </td></tr>
      </table>
      <div class="invalid-feedback">{{errors.acceptTerms}}</div>

      <div class="cf-turnstile" data-sitekey="0x4AAAAAAAhcNb4MpqhrCNFc"></div>
      <div id="turnstile"></div>
        
      <button @click="submitForm" value="SEND"
        data-wait="Please wait..."
        class="intakeformbutton w-button">
        SEND
      </button>
  </Form>

  <div class="simple-email-form-response">
	<div class="simple-email-form-success-message">
		<div>Thank you! Your submission has been received!</div>
	</div>
	<div class="simple-email-form-failure-message">
		<div>Oops! Something went wrong while submitting the form.</div>
	</div>
  </div>
  <div class="build-version-field" style="color: #0f0; margin-bottom: 5px;"></div>
<!--/div-->
<!--/div-->
</template>

<script>
import app_config from '@/config';
import tmt from '@/tmt';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';

export default {
  components: {
    // Rename form to avoid confusion with native `form`
    Form,
    Field,
  },
  setup() {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = yup.object({
      email: yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      fullname: yup.string()
        .required('Name is required'),
      address: yup.string()
        .required('Address is required'),
      business: yup.string()
        .required('Business is required'),
      phone: yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone is required'),
    });
    return {
      schema
    }
  },
  methods: {
    submitForm(values) {
      //TODO:  DO we need to popup an alert if failed validation?
      tmt.collect_form_and_submit(window.turnstileToken);
    }
  },
  mounted: function () {
    tmt.init_page();
  },
};
</script>
