var logger = {

    log(msg, level) {
        if (level === 'error') {
            console.error(msg);
        } else if (level === 'debug') {
            console.debug(msg);
        } else if (level === 'info') {
            console.log(msg);
        }
    },

    alert(msg) {
        //TODO: Turn off in production
        //alert(msg);
        logger.log(msg, 'debug')
    }

}
export default logger;